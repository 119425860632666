.jsoneditor.jsoneditor-mode-code + .jsoneditor.jsoneditor-mode-code {
    display: none !important;
}

.jsoneditor.jsoneditor-mode-view + .jsoneditor.jsoneditor-mode-view {
    display: none !important;
}

.editor-view-mode .jsoneditor-menu {
    display: none;
}

.editor-view-mode .jsoneditor-navigation-bar {
    display: none;
}

.editor-view-mode .jsoneditor {
    padding: 8px;
    border-radius: 8px;
    overflow: hidden;
}

.editor-view-mode .jsoneditor * {
    font-family: Inter, sans-serif;
    line-height: 130%;
}

.jsoneditor {
    border: 1px solid #CFD6E5;
}

.editor-code-mode .jsoneditor {
    border-radius: 8px;
}

/*styles for disabled state*/
.jsoneditor-preview {
    padding: 12px !important;

    font-family: Inter, sans-serif;
    color: #1F2125;
    background-color: #F3F5F8;
}

/* remove highlights of active row */
.editor-code-mode .ace-jsoneditor .ace_marker-layer .ace_active-line, .editor-code-mode .ace-jsoneditor .ace_gutter-active-line {
    background-color: transparent;
}

.editor-code-mode .ace-jsoneditor .ace_gutter {
    background-color: #CFD6E5;
}

.jsoneditor-transform, .jsoneditor-sort, .jsoneditor-poweredBy {
    display: none;
}

/* ---------------------- Left Side Numerical Bar Styling ----------------------*/

.editor-code-mode .ace_gutter {
    font-family: Inter, sans-serif;
    color: #1F2125;
}

/* ---------------------- Bottom Status Bar Styling ----------------------*/

.editor-code-mode .jsoneditor-statusbar {
    padding-left: 8px;
    font-family: Inter, sans-serif;

    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top: 1px solid #CFD6E5;

    background-color: #FFFFFF;
    color: #000000;
}

/*-----------------------Menu Styling------------------------*/

.jsoneditor-menu {
    display: flex;

    background-color: #FFFFFF;

    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 1px solid #CFD6E5;
}

.editor-code-mode .jsoneditor-menu > button {
    opacity: 1;
    margin-left: 4px;
    margin-right: 4px;

    background-size: 17px;
    background-position: center;
    background-repeat: no-repeat;
}

.editor-code-mode .jsoneditor-menu > button:disabled {
    opacity: 0.5;
    background-position: center;
}

/*separator line*/
.editor-code-mode .jsoneditor-menu > .jsoneditor-poweredBy {
    position: static;
    display: block;

    width: 1px;
    height: 20px;
    padding: 0;
    margin: auto 0 auto 8px;

    font-size: 0px;
    background-color: #CFD6E5;
    color: transparent;

    pointer-events: none;
}

.jsoneditor-menu > .jsoneditor-format, .jsoneditor-menu > .jsoneditor-compact, .jsoneditor-menu > .jsoneditor-sort, .jsoneditor-menu > .jsoneditor-transform {
    display: none;
}

.jsoneditor-menu > .jsoneditor-repair {
    background-image: url( "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'%3E%3Cpath d='M9.80088 4.20015C9.67873 4.32477 9.61031 4.49231 9.61031 4.66682C9.61031 4.84132 9.67873 5.00886 9.80088 5.13348L10.8675 6.20015C10.9922 6.3223 11.1597 6.39072 11.3342 6.39072C11.5087 6.39072 11.6763 6.3223 11.8009 6.20015L14.3142 3.68682C14.6494 4.42761 14.7509 5.25297 14.6052 6.05291C14.4594 6.85285 14.0734 7.58938 13.4984 8.16433C12.9234 8.73929 12.1869 9.12537 11.387 9.27112C10.587 9.41687 9.76167 9.31537 9.02088 8.98015L4.41421 13.5868C4.149 13.852 3.78929 14.001 3.41421 14.001C3.03914 14.001 2.67943 13.852 2.41421 13.5868C2.149 13.3216 2 12.9619 2 12.5868C2 12.2117 2.149 11.852 2.41421 11.5868L7.02088 6.98015C6.68566 6.23936 6.58416 5.41399 6.72991 4.61405C6.87566 3.81411 7.26174 3.07759 7.83669 2.50263C8.41165 1.92767 9.14818 1.5416 9.94812 1.39584C10.7481 1.25009 11.5734 1.35159 12.3142 1.68682L9.80755 4.19348L9.80088 4.20015Z' stroke='%231F2125' stroke-width='1.2' /%3E%3C/svg%3E" ) !important;
}

.jsoneditor-menu > .jsoneditor-undo {
    background-image: url( "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath d='M3.88432 4.6667L5.57467 6.35706L4.63186 7.29986L1.33203 4.00003L4.63186 0.700195L5.57467 1.64301L3.88432 3.33336H8.66536C11.6109 3.33336 13.9987 5.72118 13.9987 8.66673C13.9987 11.6122 11.6109 14.0001 8.66536 14.0001H2.66536V12.6667H8.66536C10.8745 12.6667 12.6654 10.8759 12.6654 8.66673C12.6654 6.45756 10.8745 4.6667 8.66536 4.6667H3.88432Z' fill='%231F2125' /%3E%3C/svg%3E" ) !important;
}

.jsoneditor-menu > .jsoneditor-redo {
    background-image: url( "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath d='M12.1157 4.6667L10.4253 6.35706L11.3681 7.29986L14.668 4.00003L11.3681 0.700195L10.4253 1.64301L12.1157 3.33336H7.33464C4.3891 3.33336 2.0013 5.72118 2.0013 8.66673C2.0013 11.6122 4.3891 14.0001 7.33464 14.0001H13.3346V12.6667H7.33464C5.1255 12.6667 3.33463 10.8759 3.33463 8.66673C3.33463 6.45756 5.1255 4.6667 7.33464 4.6667H12.1157Z' fill='%231F2125' /%3E%3C/svg%3E" ) !important;
}
